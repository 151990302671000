import * as types from "./types";

const initialState = {
  CustomerId: "",
  RedirectUrl:"" 
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CustomerId:
      return {
        ...state,
        CustomerId: action.payload.CustomerId
      }
    case types.SET_RedirectURL:
      //console.log("in case", action);
      return {
        ...state,
        RedirectUrl: action.payload.RedirectUrl
      }
    
    default:
      return state;
  }
};

export default reducer;
