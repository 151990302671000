import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Validate from "./pages/validate";
import "./App.css";

function App() {
  return (
    <div className="App" style={{height: '100%'}}>
      <Router>
        <Switch>
          <Route path="/validate">
            <Validate />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
