import { requirePropFactory } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import urlJSON from "./urls.json";
import LoaderImage from "../../assets/Loader.png";

export default function ValidateUrl() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [processState, setProcessState] = useState("validating");

  useEffect(() => {
    setTimeout(() => {
      doValidationProcess();
    }, 1000);
  }, []);

  const getCustomerUrl = (customerId, urlJSON) => {
    const customer = urlJSON.redirectUrls.find(
      (a) => a.customer.id === customerId
    );
    return customer.customer.redirect_url;
  };

  const resolveMessageFromProcessState = (processState) => {
    switch (processState) {
      case "success":
        return `Connection with SuperOffice is successful ! `;

      case "failure":
        return `Sorry, failed to connect with SuperOffice`;

      default:
        return `Validating connection with SuperOffice`;
    }
  };

  const doValidationProcess = () => {
    try {
      const id_token = window.location.href
        .replace(/.*#/, "")
        .split("&")
        .map((s) => s.replace(/.*=/, ""))[4];

      const sytem_user_token = id_token
        .split(".")
        .map((d) => d.replace(/.*:/, ""))[1];

      let params = window.location.href.replace(/^.*validate#/, "#");

      const customerId = JSON.parse(atob(sytem_user_token))[
        "http://schemes.superoffice.net/identity/ctx"
      ];

      const customerUrl = getCustomerUrl(customerId, urlJSON);
      const redirectUrl = `${customerUrl}?${params}`;
      setProcessState("success");
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 1000);
    } catch (error) {
      setProcessState("failure");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <img src={LoaderImage} style={{ width: "50%" }} alt="Connecting" />
      <span>{resolveMessageFromProcessState(processState)}</span>
    </div>
  );
}
