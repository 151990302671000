import {
    combineReducers
} from 'redux';

import validateReducer from './pages/validate/redux/reducer'; 
 

const rootReducer = {
    validate: validateReducer,
     
};

const combinedRootReducer = combineReducers(rootReducer);

export default combinedRootReducer;